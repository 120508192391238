import { FC, useState } from 'react'

import { AddressInput, WrappedInputProps } from '../../../components/Form'
import { useFormValues } from '../../../hooks'
import { addressValidators, commonValidators, onlyDigits } from '../../../utils'
import './style.less'
import { AddressElements } from './types'
import { getPartPrefixAttribute } from './utils'

type AddressDetailsProps = Partial<WrappedInputProps> & {
    prefix: string
    uneditable: boolean
    marginLeft?: number
    setAddressValue?: (address: string) => void
    group?: string
}

export const AddressDetailsSimple: FC<AddressDetailsProps> = ({
    uneditable,
    prefix = '',
    marginLeft = 240,
    setAddressValue,
    group,
    fieldKey,
    listName,
    ...restProps
}) => {
    const getNameForField = (fieldName: string): string | [number, string] => {
        if (typeof fieldKey === 'number') {
            return [fieldKey, `${prefix}.${fieldName}`]
        }

        return fieldName
    }

    const { getFormValue } = useFormValues({ listName })
    const [address, setAddress] = useState(() => {
        if (typeof fieldKey === 'number') {
            return Object.values(AddressElements).map((addressElement) => {
                const filedName = getNameForField(addressElement)

                const value = {
                    name: addressElement,
                    value: getFormValue(filedName),
                }

                return value
            })
        }
    })

    const changeAddress = (
        partAddress?: AddressElements,
        e?: React.ChangeEvent<HTMLInputElement>,
    ) => {
        let newAddress = address
        if (partAddress) {
            newAddress = newAddress?.map((el) =>
                el.name === partAddress ? { ...el, value: e?.target.value || '' } : el,
            )

            setAddress(newAddress)
        }

        newAddress && setAddressValue?.(newAddress.map(({ value }) => value).join(', '))
    }

    const commonProps = (addressElement: AddressElements) => ({
        ...restProps,
        fieldKey,
        listName,
        placeholder: '',
        uneditable,
        name: getNameForField(addressElement),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => changeAddress(addressElement, e),
    })

    return (
        <div className="vertical-inputs" style={{ marginLeft }}>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.region)}
                    label="Регион"
                    width={292}
                    required
                    rules={[{ validator: commonValidators.address(50) }]}
                />
                <AddressInput
                    {...commonProps(AddressElements.district)}
                    label="Район"
                    width={292}
                    rules={[{ validator: commonValidators.address(50) }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.locality)}
                    label="Населенный пункт: город, село, деревня и др."
                    width={600}
                    required
                    rules={[{ validator: commonValidators.address(50) }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.street)}
                    label="Улица"
                    width={600}
                    required
                    rules={[{ validator: commonValidators.address(50) }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.postIndex)}
                    label="Индекс"
                    className="no-error-messages"
                    width={292}
                    required
                    rules={[{ validator: commonValidators.number(6) }]}
                    maxLength={6}
                    normalize={onlyDigits}
                />
                <AddressInput
                    {...commonProps(AddressElements.house)}
                    label="Дом"
                    className="no-error-messages"
                    width={292}
                    required
                    rules={[{ validator: addressValidators.house }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.building)}
                    label="Строение"
                    width={190}
                    rules={[{ validator: addressValidators.house }]}
                />
                <AddressInput
                    {...commonProps(AddressElements.buildingPart)}
                    label="Корпус"
                    width={190}
                    rules={[{ validator: addressValidators.house }]}
                />
                <AddressInput
                    {...commonProps(AddressElements.place)}
                    label="Квартира"
                    width={190}
                    rules={[{ validator: addressValidators.house }]}
                />
            </div>
        </div>
    )
}
