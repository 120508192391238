import cn from 'classnames'
import { FC, useEffect, useState } from 'react'

import { Checkbox, CheckboxChangeEvent } from '../../../Checkbox'
import { Space } from '../../../Space'
import { CardDivider } from '../../../card'
import { WrappedInput, WrappedInputProps } from '../../../components/Form'
import './style.less'
import { setDetailedAddress } from './utils'
import { useFormValues } from '../../../hooks'
import { AddressDetailsSimple } from './AddressDetailsSimple'

export type AddressFormSimpleProps = Partial<WrappedInputProps> & {
    uneditable: boolean
    addressTitle?: string
    group?: string
    noActualAddressCheckbox?: boolean
    noRegistrationAddressCheckbox?: boolean
    noBottomDivider?: boolean
    isLegalAddress?: boolean
    isPostAddressRequired?: boolean
}

export const AddressFormSimple: FC<AddressFormSimpleProps> = ({
    uneditable,
    addressTitle = 'Место регистрации',
    group,
    noActualAddressCheckbox = false,
    noRegistrationAddressCheckbox = false,
    noBottomDivider = false,
    isLegalAddress,
    fieldKey,
    listName,
    isPostAddressRequired,
    ...restProps
}) => {
    const { getFormValue, setFormValue } = useFormValues({ listName })

    const [isFactSameAsRegistration, setIsFactSameAsRegistration] = useState(true)
    const [isFactSame, setIsFactSame] = useState(true)
    const [isEmptyPostAddress, setIsEmptyPostAddress] = useState(false)

    useEffect(() => {
        if (typeof fieldKey === 'number') {
            const registrationValue = getFormValue([fieldKey, 'registration'])
            setDetailedAddress(registrationValue, fieldKey, 'registration', setFormValue)

            const factValue = getFormValue([fieldKey, 'fact'])
            setDetailedAddress(factValue, fieldKey, 'fact', setFormValue)

            const indexValue = getFormValue([fieldKey, 'index'])
            if (!indexValue) {
                setIsEmptyPostAddress(true)
                setIsFactSame(false)
            }

            setDetailedAddress(indexValue, fieldKey, 'index', setFormValue)
        }
    }, [fieldKey])

    const getNameForField = (fieldName: string): string | [number, string] => {
        if (group) {
            return `${group}.${fieldName}`
        }

        if (typeof fieldKey === 'number') {
            return [fieldKey, fieldName]
        }

        return fieldName
    }

    const onChangeIsFactSameAsRegistration = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        setIsFactSameAsRegistration(isChecked)

        if (isChecked) {
            const addressValue = getFormValue(getNameForField('registration'))
            setFormValue(addressValue, getNameForField('fact'))

            if (typeof fieldKey === 'number') {
                setDetailedAddress(addressValue, fieldKey, 'fact', setFormValue)
            }

            /*if (isFactSame) {
                addresses[`${_group}index`] = addressValue
            }*/
        }
    }

    const onChangeIsFactSame = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        setIsFactSame(isChecked)

        if (isChecked) {
            const addressValue = getFormValue(getNameForField('fact'))
            setFormValue(addressValue, getNameForField('index'))
            if (typeof fieldKey === 'number') {
                setDetailedAddress(addressValue, fieldKey, 'index', setFormValue)
            }
        }
    }

    const onChangeIsEmptyPostAddress = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        setIsEmptyPostAddress(isChecked)

        if (isChecked) {
            setIsFactSame(false)
            setFormValue('', getNameForField('index'))
            if (typeof fieldKey === 'number') {
                setDetailedAddress(',,,,,,,,,', fieldKey, 'index', setFormValue)
            }
        }
    }

    const factAddressLabel = isLegalAddress ? 'Адрес юридического лица' : 'Адрес проживания'
    const factSameAsRegistrationLabel =
        noRegistrationAddressCheckbox || isFactSameAsRegistration || uneditable
            ? factAddressLabel
            : ''
    const factSameLabel =
        noActualAddressCheckbox || isFactSame || uneditable ? 'Почтовый адрес' : ''

    const commonPropsForTextItem = {
        ...restProps,
        fieldKey,
        listName,
        placeholder: '',
        uneditable,
        disabled: true,
        className: 'formComponentItem--xl',
    }

    const mainAddressFormRowClassName = cn('formRow', {
        hidden: !uneditable,
    })

    const setRegistrationMainAddressValue = (value: string) => {
        setFormValue(value, getNameForField('registration'))
    }

    const setFactMainAddressValue = (value: string) => {
        setFormValue(value, getNameForField('fact'))
    }

    const setIndexMainAddressValue = (value: string) => {
        setFormValue(value, getNameForField('index'))
    }

    const postAddressLabelClassName = cn('form-label', {
        'form-label--required': isPostAddressRequired,
    })

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={mainAddressFormRowClassName}>
                <WrappedInput
                    {...commonPropsForTextItem}
                    label={`${addressTitle}:`}
                    name={getNameForField('registration')}
                />
            </div>
            {!uneditable && (
                <div className="formRow">
                    <div className="form-label form-label--required">{addressTitle}:</div>
                    <AddressDetailsSimple
                        uneditable={uneditable}
                        prefix="registration"
                        fieldKey={fieldKey}
                        listName={listName}
                        setAddressValue={setRegistrationMainAddressValue}
                        marginLeft={0}
                    />
                </div>
            )}

            {!noRegistrationAddressCheckbox && !uneditable && (
                <div className="formRow">
                    <div className="form-label form-label--required">{factAddressLabel}:</div>
                    <Checkbox
                        defaultChecked={isFactSameAsRegistration}
                        onChange={onChangeIsFactSameAsRegistration}
                    >
                        Совпадает с {isLegalAddress ? 'юридическим' : 'регистрацией'}
                    </Checkbox>
                </div>
            )}

            <div className="formRow">
                <WrappedInput
                    {...commonPropsForTextItem}
                    label={factSameAsRegistrationLabel}
                    name={getNameForField('fact')}
                    hidden={!uneditable}
                />
            </div>

            {!uneditable && !isFactSameAsRegistration && (
                <div className="formRow">
                    <div className="form-label form-label--required">{addressTitle}:</div>
                    <AddressDetailsSimple
                        uneditable={uneditable}
                        prefix="fact"
                        fieldKey={fieldKey}
                        listName={listName}
                        setAddressValue={setFactMainAddressValue}
                        marginLeft={0}
                    />
                </div>
            )}

            {!noActualAddressCheckbox && !uneditable && (
                <div className="formRow">
                    <div className={postAddressLabelClassName}>Почтовый адрес:</div>
                    <Space direction="vertical" style={{ width: 'auto' }}>
                        {!isPostAddressRequired && (
                            <Checkbox
                                checked={isEmptyPostAddress}
                                onChange={onChangeIsEmptyPostAddress}
                            >
                                Нет адреса
                            </Checkbox>
                        )}
                        <Checkbox
                            checked={isFactSame}
                            onChange={onChangeIsFactSame}
                            disabled={isEmptyPostAddress}
                        >
                            Совпадает с фактическим
                        </Checkbox>
                    </Space>
                </div>
            )}

            <div className="formRow">
                <WrappedInput
                    {...commonPropsForTextItem}
                    label={factSameLabel}
                    name={getNameForField('index')}
                    hidden={!uneditable}
                />
            </div>

            {!uneditable && !isFactSame && !isEmptyPostAddress && (
                <AddressDetailsSimple
                    uneditable={uneditable}
                    prefix="index"
                    fieldKey={fieldKey}
                    listName={listName}
                    setAddressValue={setIndexMainAddressValue}
                    marginLeft={0}
                />
            )}

            {!noBottomDivider && <CardDivider />}
        </div>
    )
}
